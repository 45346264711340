.btn {
  $this: &;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  appearance: none;
  border: none;
  background: transparent;
  padding: 0px 18px;
  font-size: 14px;
  height: 44px;
  @include bold;
  color: $white;
  background: $blue;
  border-radius: 4px;
  transition: background $anim;
  box-sizing: border-box;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  @include sm {
    height: 36px;
    padding: 0 9px;
    font-size: 16px;
    @include medium; }
  @include hover {
    background: $blue3; }
  &__figure-svg {
    @include coverdiv;
    z-index: 0;
    @include mob {
      display: none; }
    .svg-bg {
      fill: $blue;
      transition: fill $anim; } }
  &--green {
    background: $green;
    @include hover {
      background: $green2; } }
  &--figure, &--figure-big {
    position: relative;
    background: transparent;
    height: 64px;
    padding: 0 56px;
    @include reg;
    text-transform: none;
    &::before {
      content: '';
      @include vcenter;
      z-index: -1;
      width: 90%;
      height: 50px;
      border-radius: 15px;
      box-shadow: 0px 0 30px 20px rgba($black, 0.6);
      @include mob {
        display: none; } }
    @include mob {
      height: 37px;
      padding: 0 9px;
      background: $blue!important;
      @include bold; }
    span {
      position: relative;
      z-index: 1;
      font-size: 24px;
      @include mob {
        font-size: 16px; } }
    @include hover {
      background: transparent;
      .svg-bg {
        fill: $blue2; } } }
  &--figure-big {
    span {
      font-size: 34px;
      @include mob {
        font-size: 16px; } } }
  &--border {
    @include medium;
    color: $yellow5;
    padding: 0 13px;
    font-size: 15px;
    border: 1px solid $yellow5;
    b {} } }
