.bonus-box {
  &__inner {}
  &__btn {
    display: block;
    position: relative;
    height: 0;
    font-size: 0;
    padding-bottom: 71%;
    overflow: hidden;
    @include hover {
      &::after {
        opacity: 1; } }
    &::after {
      content: '';
      @include coverdiv;
      z-index: 1;
      opacity: 0;
      background: rgba($black, 0.6);
      transition: opacity $anim; }
    img {
      @include coverdiv;
      z-index: 0;
      object-fit: cover; } }
  &__text {
    padding: 15px 5px 0;
    font-size: 24px;
    @include bold;
    color: $black;
    text-align: center;
    @include md {
      padding: 12px 0 0;
      font-size: 16px;
      @include reg; }
    @include mob {
      color: $red4; } } }
