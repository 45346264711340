.table-block {
  padding: 10px 0 40px;
  background: $orange;
  table {
    border-collapse: separate;
    border-spacing: 0px 47px;
    table-layout: fixed;
    @include md {
      table-layout: auto; }
    tr {
      td {
        &:nth-of-type(1) {
          width: 46%;
          @include md {
            width: calc(50% - 10px); } }
        &:nth-of-type(2) {
          width: 8%;
          border: none;
          @include md {
            width: 20px; } }
        &:nth-of-type(3) {
          width: 54%;
          @include md {
            width: calc(50% - 10px); } } } }
    td {
      border-bottom: 1px solid $grey;
      font-size: 18px;
      @include bold;
      color: $grey;
      @include md {
        font-size: 14px;
        @include reg; } } } }
