.hero {
  $this: &;
  margin-top: 74px;
  margin-bottom: 70px;
  @include md {
    margin-top: 40px;
    margin-bottom: 36px; }
  @include mob {
    margin-top: 0; }
  &__inner {
    position: relative;
    height: 0;
    padding-bottom: 39.6%; }
  &__bg {
    @include coverdiv;
    z-index: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }
  &--btn {
    margin: 0;
    @include md {
      margin: 0; }
    #{$this}__inner {
      padding-bottom: 35.7%; } }
  &__content {
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 30px;
    width: 100%;
    text-align: center;
    @include sm {
      bottom: 10px; } } }


