.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: initial;
}
.icon-play {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-star {
  width: 1.08em;
  height: 1em;
  fill: initial;
}
