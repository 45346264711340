.scroll-panel {
  display: none;
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5px 10px 5px;
  background: $red5;
  transform: translateY(102%);
  transition: transform $anim;
  @include is-touch {
    display: block; }
  &.is-active {
    transform: translateY(0); }
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  &__right {
    text-align: right; }
  &__block {
    display: flex;
    align-items: center;
    p {
      padding-top: 3px;
      font-size: 13px;
      color: $yellow5;
      @include medium;
      strong {
        @include semibold; } } }
  &__img {
    width: 44px;
    height: 44px;
    margin-right: 7px;
    flex-shrink: 0;
    flex-grow: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain; } } }
