.rating {
  display: flex;
  justify-content: center;
  &__btn {
    font-size: 0;
    line-height: 1;
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 7px; }
    svg {
      width: 75px;
      height: 71px;
      fill: $yellow3;
      transition: fill $anim;
      @include mob {
        width: 10vw;
        height: 10vw;
        @include land {
          @include sm {
            width: 8vw;
            height: 8vw; }
          @include xxs {
            width: 11vw;
            height: 11vw; } }
        @include port {
          width: 10vw;
          height: 10vw;
          @include xxs {
            width: 9vw;
            height: 9vw; } } } }

    &.is-active, &.is-hover {
      svg {
        fill: $yellow4; } } } }


