.container, .container-big {
  padding: 0 60px;
  margin: 0 auto;
  @include md {
    padding: 0 20px; } }
.container {
  max-width: 1330px; }
.container-big {
  max-width: 1350px;
  padding: 0 20px; }
