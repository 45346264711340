
@mixin bold {
  font-family: 'Roboto', Arial, sans-serif;
  font-weight: 700;
  font-style: normal; }
@mixin semibold {
  font-family: 'Roboto', Arial, sans-serif;
  font-weight: 600;
  font-style: normal; }
@mixin medium {
  font-family: 'Roboto', Arial, sans-serif;
  font-weight: 500;
  font-style: normal; }
@mixin reg {
  font-family: 'Roboto', Arial, sans-serif;
  font-weight: 400;
  font-style: normal; }
