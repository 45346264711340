.game-box {
  $this: &;
  position: relative;
  @include hover {
    #{$this}__content, #{$this}__title {
      opacity: 1; } }
  &__main {
    position: relative;
    height: 0;
    padding-bottom: 112%;
    border-radius: 4px;
    overflow: hidden;
    font-size: 0;
    @include sm {
      border-radius: 10px; }

    @include is-touch {
      &.is-active {
        #{$this}__content {
          opacity: 1;
          pointer-events: all; }
        & + #{$this}__title {
          opacity: 1; } } } }
  &__bg, &__content {
    @include coverdiv; }
  &__bg {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }
  &__content {
    z-index: 1;
    background: rgba($black, 0.6);
    transition: opacity $anim;
    height: 100%;
    opacity: 0;
    @include is-touch {
      pointer-events: none; }
    @include md {
      padding-top: 0;
      display: flex;
      justify-content: center;
      align-items: center; } }
  &__btn-wrap {
    display: flex;
    justify-content: center;
    @include vcenter;
    top: 52%;
    button {
      @include hover {
        svg {
          fill: $orange2; } } }
    svg {
      width: 104px;
      height: 104px;
      fill: $orange1;
      transition: fill $anim;
      @include grid {
        width: 7.5vw;
        height: 7.5vw; }
      @include md {
        width: 21vw;
        height: 21vw; } } }
  &__title {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 25px;
    width: 100%;
    padding: 0 5px;
    text-align: center;
    transition: opacity $anim;
    opacity: 0;
    @include md {
      padding: 12px 0 0;
      position: static;
      opacity: 1; }
    a {
      font-size: 20px;
      @include bold;
      color: $white;
      transition: color $anim;
      @include hover {
        color: $yellow; }
      @include grid {
        font-size: 16px; }
      @include md {
        color: $black; } } } }


