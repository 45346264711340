@mixin burger-styles {
  display: none;
  position: absolute;
  z-index: 100;
  left: -7px;
  top: calc(100% + 20px);
  min-width: 190px;
  padding: 17px 33px 19px;
  background: $red;
  border-radius: 10px;
  @include lg {
    right: -15px; }
  @include md {
    padding: 15px 20px; }
  @include mob {
    top: calc(100% + 15px); }
  a {
    display: block;
    line-height: 1.2;
    font-size: 14px;
    @include bold;
    color: $yellow;
    transition: color $anim;
    @include hover {
      color: $white; } }
  li {
    &:not(:last-of-type) {
      margin-bottom: 20px; } } }
.header {
  $this: &;
  position: relative;
  z-index: 100;
  width: 100%;
  background: $main-red;
  &__inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 60px 40px 3px;
    @include md {
      padding: 30px 30px 8px; }
    @include mob {
      padding: 11px 15px 8px; }
    @include mob {
      align-items: center; } }
  &__nav-side {
    display: flex;
    align-items: center;
    padding-top: 16px;
    @include md {
      padding-top: 4px; } }
  &__btns-side {
    font-size: 0;
    flex-grow: 0; }
  &__btn {
    margin-left: 20px;
    &--hidden-mob {
      @include mob {
        display: none; }
      @include sm {
        display: none; } } }
  &__burger-wrapper {
    position: relative; }
  &.is-only-burger {
    #{$this}__menu {
      @include burger-styles; } }
  &:not(.is-only-burger) {
    #{$this}__menu {
      @include rmin(1025) {
        display: block!important;
        ul {
          display: flex; }
        li {
          &:not(:last-of-type) {
            margin-right: 48px;
            @include lg {
              margin-right: 20px; } } }
        a {
          font-size: 24px;
          @include bold;
          color: $yellow2;
          transition: color $anim;
          @include hover {
            color: $white; }
          @include lg {
            font-size: 18px; } } }
      @include r(1024) {
        @include burger-styles; } }
    #{$this}__inner {
      @include rmin(1025) {
        padding: 66px 40px 10px; } }
    .burger {
      @include rmin(1025) {
        display: none; } } } }
.header-logo {
  display: block;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 14px);
  font-size: 0;
  @include mob {
    transform: translate(-50%, 7px); }
  &__img {
    width: 198px;
    height: 129px;
    @include md {
      width: 140px;
      height: 89px; }
    @include mob {
      width: 86px;
      height: 59px; } } }

.burger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  background: $red3;
  border-radius: 6px;
  cursor: pointer;
  @include mob {
    width: 36px;
    height: 36px; }
  &__inner {
    position: relative;
    width: 28px;
    height: 28px;
    @include mob {
      width: 22px;
      height: 22px; } }
  span {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 4px;
    background: $yellow2;
    transition: transform $anim, opacity $anim, width $anim;
    border-radius: 1px;
    &:nth-of-type(1) {
      transform-origin: 0 0;
      top: 0; }
    &:nth-of-type(2) {
      top: 50%;
      margin-top: -2px; }
    &:nth-of-type(3) {
      bottom: 0;
      transform-origin: 0% 100%; } }
  &.is-active {
    span {
      &:nth-of-type(1) {
        transform: rotate(40deg);
        width: 138%;
        @include mob {
          width: 136%;
          transform: rotate(38deg); } }
      &:nth-of-type(2) {
        opacity: 0; }
      &:nth-of-type(3) {
        transform: rotate(-40deg);
        width: 138%;
        @include mob {
          width: 136%;
          transform: rotate(-38deg); } } } } }


