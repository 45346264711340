.article-block {
  padding: 20px 0 40px;
  background: $orange;
  @include sm {
    padding: 18px 0; }
  &__inner {
    font-size: 20px;
    color: $grey;
    @include reg;
    @include sm {
      font-size: 14px; }
    h1, h2, h3 {
      margin: 24px 0;
      @include bold;
      text-align: center;
      @include sm {
        margin-bottom: 10px 0; } }
    h1 {
      font-size: 45px;
      margin: 30px 0;
      @include sm {
        font-size: 18px;
        margin: 12px 0; } }
    h2 {
      font-size: 36px;
      @include sm {
        font-size: 16px; } }
    h3 {
      font-size: 30px;
      @include sm {
        font-size: 14px; } }
    p {
      text-indent: 26px;
      margin-bottom: 20px;
      @include sm {
        margin-bottom: 10px; } }
    ul, ol {
      margin-top: 50px;
      margin-bottom: 50px;
      list-style-type: none;
      @include sm {
        margin: 30px 0; } }
    ul {
      li {
        &::before {
          content: '-';
          margin-right: 8px; } } }
    ol {
      @include counter(0, ' '); } }
  a {
    color: $orange2;
    transition: color $anim;
    @include hover {
      color: $orange1; } }
  img {
    display: block;
    max-width: 800px;
    width: 100%;
    height: auto;
    margin: 40px auto; } }
