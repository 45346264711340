.game-block {
  $this: &;
  position: relative;
  padding: 75px 0;
  @include mob {
    background: $orange;
    padding: 0 0 40px; }
  &__bg {
    @include coverdiv;
    z-index: 0;
    @include mob {
      display: none; }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }
  &__main {
    position: relative;
    z-index: 1; }
  &__inner {
    @include md {
      display: flex;
      flex-wrap: wrap; } }
  &__btn-outside {
    margin-bottom: 25px;
    text-align: center;
    @include md {
      width: 100%;
      order: 1; }
    @include md {
      margin-bottom: 0;
      padding-top: 20px; }
    @include mob {
      padding-top: 10px; } }
  &__game-wrapper {
    max-width: 756px;
    margin: 0 auto;
    @include md {
      order: 0;
      width: 100%;
      flex-grow: 1; } }
  &__game-inner {
    position: relative;
    height: 0;
    padding-bottom: 67.2%;
    overflow: hidden;
    img {
      @include coverdiv;
      z-index: 0;
      transition: opacity $anim;
      pointer-events: none; }
    iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1; } }
  &__btn {
    width: 174px;
    height: 174px;
    @include vcenter;
    z-index: 1;
    cursor: pointer;
    transition: opacity $anim;
    border-radius: 50%;
    overflow: hidden;
    background: $white;
    font-size: 0;
    @include hover {
      svg {
        fill: $orange2; } }
    @include md {
      width: 120px;
      height: 120px; }
    @include mob {
      width: auto;
      height: auto; }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
    svg {
      width: 100%;
      height: 100%;
      object-fit: contain;
      fill: $orange1;
      transition: fill $anim;
      @include mob {
        width: 24vw;
        height: 24vw; } } }

  &.is-active {
    #{$this}__preview, #{$this}__btn {
      opacity: 0; }
    #{$this}__btn {
      pointer-events: none; } } }

