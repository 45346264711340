body {
	background: $white;
	@include font-smoothing;
	font-size: 16px;
	line-height: 1.2; }

.out {
	position: relative; }

.main-container {
	display: flex;
	flex-wrap: wrap; }

.section {
	width: 100%; }

.order-1 {
	order: 1; }
.order-2 {
	order: 2; }
.order-3 {
	order: 3; }
.order-4 {
	order: 4; }
.order-5 {
	order: 5; }
.order-6 {
	order: 6; }
.order-7 {
	order: 7; }
.order-8 {
	order: 8; }
.order-9 {
	order: 9; }
.order-10 {
	order: 10; }

button {
	appearance: none;
	border: none;
	background: transparent; }

a {
	text-decoration: none; }

h1, h2, h3, h4, h5, h6 {
	font-weight: normal; }
