.game-plate {
  padding-bottom: 20px;
  &__inner {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -32px;
    padding: 0 30px;
    @include grid {
      margin: 0 -15px; }
    @include md {
      margin: 0 -12px; }
    @include mob {
      padding: 0; } }
  &__item {
    width: 25%;
    padding: 0 32px;
    margin-bottom: 46px;
    @include grid {
      padding: 0 15px;
      margin-bottom: 30px; }
    @include md {
      width: 50%;
      padding: 0 12px;
      margin-bottom: 30px; } } }
