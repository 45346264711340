.breadcrumbs {
  background: $orange;
  &__inner {}
  li {
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding: 25px 0 15px;
    @include sm {
      padding: 25px 0 13px; }
    &:not(:last-child) {
      &::after {
        content: '-';
        font-size: 20px;
        color: $red;
        @include reg;
        @include sm {
          font-size: 12px; } } } }
  a {
    font-size: 24px;
    color: $red;
    @include reg;
    transition: color $anim;
    @include sm {
      font-size: 12px; }
    @include hover {
      color: $red3; } }
  // .container-big
 }  //   padding: 0 20px
