$white: #fff;
$black: #000;
$main-red: #b20427;
$red: #931629;
$red1: #6a2331;
$red2: #601e2b;
$red3: #c62f42;
$red4: #C82C3F;
$red5: #B20426;
$orange: #f8f3d5;
$orange1: #ff5c2f;
$orange2: #e98e45;
$yellow: #f5df83;
$yellow2: #ffff97;
$yellow3: #f2f72b;
$yellow4: #8c8c17;
$yellow5: #FCEC6F;
$blue: #4661b8;
$blue2: #658cff;
$blue3: #5a7cea;
$green: #3bb224;
$green2: #72e25c;
$grey: #4a4a4b;

$anim: 0.25s ease-in;
