.footer {
  $this: &;
  background: $red1;
  &__top {
    padding: 100px 0 42px;
    @include md {
      padding: 50px 30px; }
    @include sm {
      padding: 28px 0 20px; } }
  &__bottom {
    padding: 28px 0 20px;
    background: $red2;
    @include is-touch {
      padding-bottom: 60px; }
    @include sm {
      padding-top: 12px; } }
  &__menu {
    @include sm {
      margin-bottom: 20px; }
    li {
      font-size: 0;
      &:not(:last-of-type) {
        margin-bottom: 16px;
        @include sm {
          margin-bottom: 32px; } } }
    a {
      font-size: 16px;
      @include bold;
      color: $yellow;
      transition: color $anim;
      @include hover {
        color: $white; }
      @include sm {
        @include reg;
        font-size: 14px; } } }
  &__logos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include sm {
      flex-wrap: wrap; }
    & > div {
      &:not(:last-child) {
        margin-right: 20px;
        @include sm {
          margin-right: 0; } } } }
  &__logo-wrapper {
    flex-shrink: 1;
    @include sm {
      display: flex;
      justify-content: center;
      padding: 7px 15px;
      height: auto!important;
      flex-grow: 1;
      box-sizing: border-box; }
    div {
      width: 100%;
      max-width: 100%;
      height: 100%; }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      @include sm {
        height: auto;
        max-width: 100%;
        width: auto; } }
    &--mastercard, &--visa {
      width: 134px;
      height: 100px;
      #{$this}__logo {
        @include sm {
          max-width: 134px; } } }
    &--skrill, &--mastercard, &--visa {
      @include sm {
        width: 33.3%; } }
    &--18 {
      width: 50px;
      height: 50px;
      @include sm {
        justify-content: flex-start;
        width: 17%;
        order: 6;
        padding: 0; }
      #{$this}__logo {
        @include sm {
          max-width: 36px; } } }
    &--dcma {
      flex-grow: 0.5;
      @include sm {
        width: 46%;
        order: 8;
        flex-grow: 2;
        justify-content: flex-end; }
      & > #{$this}__logo {
        width: auto;
        height: auto;
        @include sm {
          width: 121px;
          max-width: 100%; }
        img {
          width: auto;
          height: auto; } } }
    &--neteller {
      width: 140px;
      height: 23px;
      @include sm {
        width: 50%;
        order: 5; }
      #{$this}__logo {
        @include sm {
          max-width: 140px; } } }
    &--bitcoin {
      width: 156px;
      height: 36px;
      @include sm {
        position: relative;
        top: -4px;
        width: 50%;
        order: 4; }
      #{$this}__logo {
        @include sm {
          max-width: 156px; } } }
    &--skrill {
      width: 79px;
      height: 27px;
      @include sm {
        order: 1; }
      #{$this}__logo {
        @include sm {
          max-width: 79px; } } }
    &--qiwi {
      width: 109px;
      height: 50px;
      @include sm {
        width: 37%;
        order: 7; }
      #{$this}__logo {
        @include sm {
          max-width: 109px; } } }
    &--mastercard {
      @include sm {
        order: 3; } }
    &--visa {
      @include sm {
        order: 2; } } } }
