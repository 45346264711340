.bonus-plate {
  padding-top: 10px;
  padding-bottom: 10px;
  background: $orange;
  @include sm {
    padding: 10px 30px 20px; }
  &__inner {
    display: flex;
    flex-wrap: wrap;
    @include sm {
      display: block;
      text-align: center; } }
  &__item {
    margin-bottom: 40px;
    width: percentage(380/1350);
    flex-grow: 0;
    @include sm {
      max-width: 500px;
      width: auto;
      margin: 0 auto 26px; }
    &:not(:nth-child(3n)) {
      margin-right: percentage(105/1350);
      @include sm {
        margin-right: auto; } } }
  .container-big {
    max-width: 1390px; } }
