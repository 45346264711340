.banner-block {
  $this: &;
  &__inner {
    position: relative; }
  &__btn-wrapper {
    width: 100%;
    margin: 0 auto; }
  &__btn {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    overflow: hidden;
    font-size: 0;
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; } }
  &__rating {
    padding-top: 10px;
    @include sm {
      padding-top: 10px; } }
  &--728 {
    padding: 110px 0 120px;
    @include md {
      padding: 80px 0; }
    @include sm {
      padding: 40px 0; }
    #{$this}__btn-wrapper {
      max-width: 728px;
      @include sm {
        padding: 0 40px; } }
    #{$this}__btn {
      padding-bottom: 56.8%; } }
  &--680 {
    padding: 75px 0 50px;
    @include mob {
      padding: 42px 0; }
    #{$this}__btn-wrapper {
      max-width: 680px;
      @include mob {
        width: 78%;
        @include sm {
          max-width: 374px; } } }
    #{$this}__btn {
      padding-bottom: 54.2%; } }
  &--630 {
    padding: 65px 0 65px;
    @include sm {
      padding: 36px 0 38px; }
    #{$this}__btn-wrapper {
      max-width: 630px;
      @include sm {
        padding: 0 35px; } }
    #{$this}__btn {
      padding-bottom: 68.2%; } } }

